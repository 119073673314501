<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg9 xl7>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-cash-register</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varUsuarioLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varUsuarioLocal.usuario"
                    label="Usuário"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varUsuarioLocal.nome"
                    label="Nome"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varUsuarioLocal.email"
                    label="E-mail"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varUsuarioLocal.whatsapp"
                    label="Whatsapp"
                    outlined
                    readonly
                    v-mask="'+## (##) # ####-####'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
          <template>
            <ContUsuariosFormLista />
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import DatasMixin from "@/mixins/DatasMixin";
import ContUsuariosFormLista from "./ContUsuariosFormLista.vue";

const { mapState, mapActions } = createNamespacedHelpers("contUsuarios");

export default {
  mixins: [validationMixin, DatasMixin],
  components: {
    ContUsuariosFormLista,
  },

  data: () => ({
    name: "ContUsuariosForm",
    tituloForm: "",
    varUsuarioLocal: {},
  }),

  computed: {
    ...mapState([
      "staUsuarios",
      "staUsuarioSelecionado",
      "staUsuarioSelect",
      "staPFisicas",
      "staPJuridicas",
    ]),

    compItensSelect() {
      let itensDisponiveis = [];
      if (this.varUsuarioLocal.tipo_pessoa === "Fisica") {
        itensDisponiveis = this.staPFisicas;
      } else if (this.varUsuarioLocal.tipo_pessoa === "Juridica") {
        itensDisponiveis = this.staPJuridicas;
      }

      return itensDisponiveis.filter(
        (pessoa) =>
          pessoa.id === this.varUsuarioLocal.id_pessoa ||
          !this.staUsuarios.some(
            (caixa) =>
              caixa.id_pessoa === pessoa.id &&
              caixa.tipo_pessoa === this.varUsuarioLocal.tipo_pessoa
          )
      );
    },

    compLabelSelect() {
      return this.varUsuarioLocal.tipo_pessoa === "Fisica"
        ? "Nome"
        : "Razão Social";
    },
  },

  watch: {
    "varUsuarioLocal.id_pessoa": function (newVal) {
      this.metPreencherCpfCnpj(newVal);
    },

    staUsuarioSelecionado(newVal) {
      this.metSincronizar(newVal);
    },
  },

  created() {
    this.metSincronizar(this.staUsuarioSelecionado);

    if (Object.keys(this.staUsuarioSelecionado).length !== 0) {
      this.tituloForm = "Editar Usuario";
    } else {
      this.tituloForm = "Erro...";
    }
  },

  methods: {
    ...mapActions([
      "actUsuarioSelecionado",
      "actResetarUsuario",
      "actUsuarioInsert",
      "actUsuarioUpdate",
      "actResetarErro",
      "actAtualizaPFisicas",
      "actAtualizaPJuridicas",
    ]),

    metPreencherCpfCnpj(idPessoa) {
      if (this.varUsuarioLocal.tipo_pessoa === "Fisica") {
        const pf = this.staPFisicas.find((pf) => pf.id === idPessoa);
        if (pf) {
          this.varUsuarioLocal.cpf_cnpj = pf.cpf; // Assumindo que o campo se chama 'cpf'
        }
      } else if (this.varUsuarioLocal.tipo_pessoa === "Juridica") {
        const pj = this.staPJuridicas.find((pj) => pj.id === idPessoa);
        if (pj) {
          this.varUsuarioLocal.cpf_cnpj = pj.cnpj; // Assumindo que o campo se chama 'cnpj'
        }
      }
    },

    async metCarregarPessoas() {
      await this.actAtualizaPFisicas({
        licenca: this.$store.state.login.licenca,
      });
      await this.actAtualizaPJuridicas({
        licenca: this.$store.state.login.licenca,
      });
    },

    async metSalvar() {
      if (this.varUsuarioLocal.id === undefined) {
        // Insert
        const pessoaSelecionada = this.compItensSelect.find(
          (p) => p.id === this.varUsuarioLocal.id_pessoa
        );
        this.varUsuarioLocal.nome_razao_social = pessoaSelecionada.nome;

        this.varUsuarioLocal.criacao = this.$store.state.login.usuario.usuario;
        this.varUsuarioLocal.modificacao =
          this.$store.state.login.usuario.usuario;
        await this.actUsuarioInsert({
          caixa: this.varUsuarioLocal,
          licenca: this.$store.state.login.licenca,
        });
        this.tituloForm = "Editar Usuario";
        this.$root.snackbar.show({
          type: "success",
          message: "Usuario Criada!",
        });
      } else {
        // Update
        const pessoaSelecionada = this.compItensSelect.find(
          (p) => p.id === this.varUsuarioLocal.id_pessoa
        );
        this.varUsuarioLocal.nome_razao_social = pessoaSelecionada.nome;
        this.varUsuarioLocal.modificacao =
          this.$store.state.login.usuario.usuario;
        this.varUsuarioLocal.modificado = undefined;
        this.varUsuarioLocal.criado = undefined;
        await this.actUsuarioUpdate({
          caixa: this.varUsuarioLocal,
          licenca: this.$store.state.login.licenca,
        });

        this.$root.snackbar.show({
          type: "success",
          message: "Usuario Salva!",
        });
      }
      //this.resetar();
    },

    metSincronizar(usuario) {
      this.varUsuarioLocal = Object.assign({}, usuario || {});
    },

    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
