<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <template>
          <v-data-table
            dense
            :headers="headers"
            :items="compUsuarioCaixas"
            sortBy="id"
            class="elevation-10"
            :footer-props="{
              'items-per-page-options': [20],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-icon class="mr-2" color="primary">mdi-cash-register</v-icon>
                <v-toolbar-title>Caixas do Usuário</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="blue darken-1"
                      v-bind="attrs"
                      v-on="on"
                      @click="varDailog = true"
                    >
                      mdi-plus-circle
                    </v-icon>
                  </template>
                  <span>Inserir Caixa</span>
                </v-tooltip>
              </v-toolbar>

              <v-container>
                <v-row dense> </v-row>
              </v-container>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dense
                    class="mr-2"
                    color="red darken-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="metExcluirItem(item)"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-dialog
            v-if="varDailog"
            v-model="varDailog"
            min-width="500px"
            max-width="700px"
          >
            <v-card>
              <v-card-title>
                <span class="text-h5">Insere Caixa</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="auto" md="10" align-self="center">
                      <v-autocomplete
                        hide-details="auto"
                        v-model="varCaixaID"
                        label="Pessoa"
                        :items="compCaixasSelect"
                        :item-text="
                          (item) =>
                            `${item.nome_razao_social} (${item.cpf_cnpj})`
                        "
                        item-value="id"
                        outlined
                        clearable
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  dense
                  class="mb-4 white--text"
                  color="green darken-2"
                  @click="metSalvar"
                >
                  Salvar
                </v-btn>
                <v-btn
                  dense
                  class="ml-2 mb-4 white--text"
                  color="blue darken-1"
                  @click="metFecharDialog"
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters } =
  createNamespacedHelpers("contUsuarios");

export default {
  // mixins: [],
  name: "ContUsuariosFormLista",
  data: () => ({
    listaUsuGruAtivos: undefined,
    headers: [
      { text: "Id", value: "id", sortable: false },
      {
        text: "Nome/Razão Social",
        value: "nome_razao_social",
        align: "left",
        sortable: false,
      },
      { text: "Pessoa", value: "tipo_pessoa", sortable: false },
      { text: "CPF/CNPJ", value: "cpf_cnpj", sortable: false },

      { text: "Ações", value: "actions", sortable: false },
    ],
    varCaixaID: undefined,
    varDailog: false,
    usuCaixa: { id: null },
    usuGruLocal: { idUsuario: null, idGrupo: null },
    usuGruLocalDefault: { id: null },
    usuGruDel: { id: null },
  }),

  computed: {
    ...mapState(["erro", "staUsuarioSelecionado", "staUsuarioCaixas"]),

    ...mapGetters(["getUsuarioCaixas", "getCaixasSelect"]),

    compUsuarioCaixas() {
      return this.getUsuarioCaixas(this.staUsuarioSelecionado.id);
    },

    compCaixasSelect() {
      return this.getCaixasSelect;
    },
  },

  created() {
    this.actUsuarioCaixas({
      licenca: this.$store.state.login.licenca,
    });
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  methods: {
    ...mapActions(["actUsuarioCaixas", "actCaixaUpdate"]),

    async metSalvar() {
      const usuCaixaOriginal = this.staUsuarioCaixas.find(
        (caixa) => caixa.id === this.varCaixaID
      );
      if (usuCaixaOriginal) {
        this.usuCaixa = Object.assign({}, usuCaixaOriginal);
        this.usuCaixa.id_usuario = this.staUsuarioSelecionado.id;
        await this.actCaixaUpdate({
          caixa: JSON.parse(JSON.stringify(this.usuCaixa)),
          licenca: this.$store.state.login.licenca,
        });

        this.$root.snackbar.show({
          type: "success",
          message: "Caixa Inserido!",
        });
        this.metFecharDialog();
      }
    },

    async metExcluirItem(item) {
      this.usuCaixa = Object.assign({}, item);
      this.usuCaixa.id_usuario = null;
      await this.actCaixaUpdate({
        caixa: JSON.parse(JSON.stringify(this.usuCaixa)),
        licenca: this.$store.state.login.licenca,
      });
    },

    metFecharDialog() {
      this.varDailog = false;
    },
  },
};
</script>
